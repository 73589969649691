import React from 'react'
// import backg from "../../assets/audio/images/sec-bg.jpg"
import Fridayimg from "../../assets/audio/images/fridaybayan.jpg"
import Morningdars from "../../assets/audio/images/morningdars.jpg"
import Hazrat from "../../assets/audio/images/hazrat1.jpg"
import MuftiTaqi from "../../assets/audio/images/muftitaqi2.jpeg"
// import ModalRamzanComponent from "../ModalRamzanComponent/ModalImage1"; 
// import Taraweeh from "../../assets/audio/images/masjid5.jpg";

import axios from 'axios'
import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet'
// import Logo1 from "../../assets/audio/images/logo185.png"
// import Logo2 from "../../assets/audio/images/logo1851.png"
import NavbarAllFiles from '../NavbarAll/NavbarAllFiles'
const IndexComponent = () => {
    // const [modalOpen, setModalOpen] = useState(false);

    // const openModal = () => {
    //   setModalOpen(true);
    // };
  
    // const closeModal = () => {
    //   setModalOpen(false);
    // };
    const [sunriseTime, setSunriseTime] = useState('');
      const [sunsetTime, setSunsetTime] = useState('');
    const [preloaderVisible, setPreloaderVisible] = useState(true);
    useEffect(() => {
        // openModal(); 
        const currentDate = new Date().toISOString().split('T')[0];
        const apiUrl = `https://api.sunrisesunset.io/json?lat=24.9331712&lng=67.1055872&date=${currentDate}`;
        // Your existing useEffect code
        // Example: Set preloader visibility to false
        // console.log(currentDate);
        // console.log(sunriseTime)
        // console.log(sunsetTime)
        // console.log(preloaderVisible)
        try {
          setPreloaderVisible(false);
        //   console.log("Seerat data fetched successfully");
        } catch (error) {
          setPreloaderVisible(false);
        //   console.error('Error fetching Seerat data:', error.message);
        }
        
        // Fetch sunrise and sunset times
        axios.get(apiUrl)
          .then(response => {
            const { sunrise, sunset } = response.data.results;
            setSunriseTime(sunrise);
            setSunsetTime(sunset);
          })
          .catch(error => {
            console.error('Error fetching sunrise and sunset data:', error);
          });
      }, [preloaderVisible,sunriseTime,sunsetTime]);
    const handleLinkClick = ()=> {
        window.scrollTo(0,0)
    }
  return (
    <div>
         <Helmet>
    <title>Bayanat</title>
    <meta name="description" content="
Bayanat at Masjid Zakaria are meant to provide Islamic teachings, interpretations of the Quran and Hadith, reminders of religious duties, guidance on ethical conduct, and discussions on contemporary issues from an Islamic perspective. They aim to foster spiritual growth, moral development, and community cohesion among attendees." />
  </Helmet> 
       <NavbarAllFiles/>
      <section>
                <div class="w-100 pt-100 pb-110 position-relative">
                    <div class="container">
                        <div class="sec-title text-center w-100" >
                            <div class="sec-title-inner d-inline-block" >
                                <h2 class="mb-0">Spirituality & Bayanat</h2>
                               
                            </div>
                        </div>
                        <div class="course-wrap2 wide-sec">
                            <div class="row mrg10 course-caro">
                                <div class="col-md-4 col-sm-6 col-lg-3">
                                    <div class="course-box2 text-center w-100">
                                        <div class="course-img2 position-relative overflow-hidden w-100" onClick={handleLinkClick}>
                                            <Link to="/fridaybayan" title=""><img class="img-fluid w-100" src={Fridayimg} alt="Course  1"/></Link>
                                        </div>
                                        <div class="course-info2 pat-bg gray-layer3 opc8 position-relative back-blend-multiply gray-bg3 w-100" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}>
                                            <h3 class="mb-0" onClick={handleLinkClick}><Link to="/fridaybayan" title="">Friday Bayan</Link></h3>
                                            <p class="mb-0">
                                                Every Friday at our mosque
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-lg-3">
                                    <div class="course-box2 text-center w-100">
                                        <div class="course-img2 position-relative overflow-hidden w-100" onClick={handleLinkClick}>
                                            <Link to="/sundaybayan" title=""><img class="img-fluid w-100" src={Morningdars} alt="Course  2"/></Link>
                                        </div>
                                        <div class="course-info2 pat-bg gray-layer3 opc8 position-relative back-blend-multiply gray-bg3 w-100" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}>
                                            <h3 class="mb-0" onClick={handleLinkClick}><Link to="/sundaybayan" title="">Morning Dars</Link></h3>
                                            <p class="mb-0">After Fajar Jamat</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-lg-3">
                                    <div class="course-box2 text-center w-100">
                                        <div class="course-img2 position-relative overflow-hidden w-100" onClick={handleLinkClick}>
                                            <Link to="/moulanaabdulsattar" title=""><img class="img-fluid w-100" src={Hazrat} alt="Moulana Abdul Sattar"/></Link>
                                        </div>
                                        <div class="course-info2 pat-bg gray-layer3 opc8 position-relative back-blend-multiply gray-bg3 w-100">
                                            <h3 class="mb-0" onClick={handleLinkClick}><Link to="/moulanaabdulsattar" title="">Moulana Abdul Sattar</Link></h3>
                                            <p class="mb-0">.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-lg-3">
                                    <div class="course-box2 text-center w-100">
                                        <div class="course-img2 position-relative overflow-hidden w-100" onClick={handleLinkClick}>
                                            <Link to="/muftitaqi" title=""><img class="img-fluid w-100" src={MuftiTaqi} alt="Course 4" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}/></Link>
                                        </div>
                                        <div class="course-info2 pat-bg gray-layer3 opc8 position-relative back-blend-multiply gray-bg3 w-100" >
                                            <h3 class="mb-0" onClick={handleLinkClick}><Link to="/muftitaqi" title="">Mufti Taqi Usmani Bayan</Link></h3>
                                            <p class="mb-0">.</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <ModalRamzanComponent
        show={modalOpen}
        onClose={closeModal}
        title="Taraweeh at Mosque"
        imageUrl={Taraweeh}
      />  */}
    </div>
  )
}

export default IndexComponent
