import React, { useState } from 'react';
import axios from 'axios';
import './AddRecordingModal.css';

const AddBayanMorningDars = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [recordingData, setRecordingData] = useState({
    title: '',
    file: null,
    date: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'file') {
      setRecordingData((prevData) => ({
        ...prevData,
        file: files[0]
      }));
    } else {
      setRecordingData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('title', recordingData.title);
      formData.append('file', recordingData.file);
      formData.append('date', recordingData.date);

      const recordingType = 'morningdars';

       await axios.post(`${apiUrl}/post-recording/${recordingType}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // console.log('Recording added successfully:', response.data);
      setRecordingData({
        title: '',
        file: null,
        date: ''
      });

    } catch (error) {
      // console.error('Error adding recording:', error);
      // Handle error, show message, etc.
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add Morning Dars Bayan</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label  style={{color:"red"}}>Title:</label>
            <input className='rectext' type="text" name="title" value={recordingData.title} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label  style={{color:"red"}}>Recording File:</label>
            <input className='recfile' type="file" name="file" onChange={handleChange} style={{ color: recordingData.file ? 'red' : 'gray' }} />
          </div>

          <div className="form-group">
            <label style={{color:"red"}}>Date:</label>
            <input type="text" name="date" value={recordingData.date} onChange={handleChange} />
          </div>

          {isLoading ? (
            <div className="loader">Loading...</div>
          ) : (
            <button type="submit">Add Recording</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddBayanMorningDars;
