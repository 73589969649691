import React, { useMemo } from "react";
import styled from "styled-components";
import { mobile } from "../../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  flex: 1;
  margin: 3px;
  height: 70vh;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;

  /* Apply zoom-out effect only for non-mobile devices */
  @media (min-width: 768px) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mobile({ height: "30vh" })}
`;

const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Display items in a column */
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background for better readability */
`;

const Title = styled.h1`
  margin: 20px;
  text-align: center; 
  color: white;
  /* Center-align the text */
`;

const Button = styled.button`
  border: none;
  padding: 10px;
  background-color: white;
  color: gray;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  :hover {
    background-color: #642828;
  }
`;

const CategoryCard = ({ item }) => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  // Memoize the Image component to prevent unnecessary re-renders
  const MemoizedImage = useMemo(() => (
    <Image src={item.img} alt={item.title} />
  ), [item.img, item.title]);

  return (
    <Container style={{ marginTop: "40px" }}>
      <Link to={item.link} onClick={handleLinkClick}>
        {MemoizedImage}
        <Info>
          <Title>{item.title}</Title>
          <Button>Explore</Button>
        </Info>
      </Link>
    </Container>
  );
};

export default CategoryCard;
