// import { useState,useEffect } from "react";
// import Mayyat from "../../assets/audio/images/mayyat.jpg"
import Nikkah from "../../assets/audio/images/nikkah.webp"
import Naat from "../../assets/audio/images/naat.jpg"
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import MosqueIcon from '@mui/icons-material/Mosque';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Link } from "react-router-dom";
// import Logo1 from "../../assets/audio/images/logo185.png"
import React, { useState, useEffect } from "react";
import NavbarAllFiles from "../NavbarAll/NavbarAllFiles";
import { Helmet } from "react-helmet";
const SeeratComponent = () => {
    const handleLinkClick = () =>{
        window.scrollTo(0,0)
    }
    const [preloaderVisible, setPreloaderVisible] = useState(true);
    useEffect(() => {
          try {
            setPreloaderVisible(false); // Set preloader visibility to false
            // console.log("Seerat data fetched successfully");
          } catch (error) {
            setPreloaderVisible(false);
            // console.error('Error fetching Seerat data:', error.message);
          }
      }, [preloaderVisible]);
     
  return (
      <>
        <Helmet>
    <title>Services</title>
    <meta name="description" content="The services provided at Masjid Zakaria include funeral services, facilitating Islamic burial rites; Nikkah services, officiating Islamic marriage ceremonies; as well as various other services such as educational programs, community events, and spiritual counseling, aiming to serve the needs of the local Muslim community comprehensively." />
  </Helmet>
      <NavbarAllFiles/>
        <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundImage: `url(${Nikkah})`}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">Services</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><Link to="/" title="Home">Masjid - Home</Link></li>
                                    <li class="breadcrumb-item active">Services</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pb-110 position-relative">
                    <div class="container">
                        <div class="sec-title text-center w-100">
                            <div class="sec-title-inner d-inline-block">
                                
                                <h2 class="mb-0">Services We Offer</h2>

                            </div>
                        </div>
                        <div class="serv-wrap wide-sec">
                            <div class="row mrg10 serv-caro">
                            <div className="col-md-4 col-sm-6 col-lg-3" onClick={handleLinkClick}>
      <Link to="/services-detail-helpingpoor">
        <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}>
        <VolunteerActivismIcon fontSize="large"  style={{color:"gray", fontSize:"90px"}}/>
          <h3 className="mb-0">Helping Poor</h3>
          {/* Icon usage */}
          
          {/* Example of commented out content */}
          <p className="mb-0" style={{color:"black"}}>Masjid Zakaria's objective of helping the poor.</p>
          <Link to="/services-detail-helpingpoor" title="" style={{color:"black"}}>Read More</Link>
        </div>
      </Link>
    </div>
                                <div className="col-md-4 col-sm-6 col-lg-3" onClick={handleLinkClick}>
      <Link to="/services-detail-mosquedev">
        <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}>
        <MosqueIcon fontSize="large"  style={{color:"gray", fontSize:"90px"}}/>
          <h3 className="mb-0">Mosque Development</h3>
          {/* Icon usage */}
          
          {/* Example of commented out content */}
          <p className="mb-0" style={{color:"black"}}>Mosque Development With Masjid Zakaria.</p>
          <Link to="/services-detail-mosquedev" title="" style={{color:"black"}}>Read More</Link>
        </div>
      </Link>
    </div>
                                <div className="col-md-4 col-sm-6 col-lg-3" onClick={handleLinkClick}>
      <Link to="/services-detail-funeral">
        <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}>
        <AirlineSeatFlatIcon fontSize="large"  style={{color:"gray", fontSize:"90px"}}/>
          <h3 className="mb-0">Funeral Service</h3>
          {/* Icon usage */}
          
          {/* Example of commented out content */}
          <p className="mb-0" style={{color:"black"}}>Funeral Service offered at Masjid Zakaria.</p>
          <Link to="/services-detail-funeral" title="" style={{color:"black"}}>Read More</Link>
        </div>
      </Link>
    </div>
    <div className="col-md-4 col-sm-6 col-lg-3" onClick={handleLinkClick}>
      <Link to="/services-detail-quranclasses">
        <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}>
        <MenuBookIcon fontSize="large"  style={{color:"gray", fontSize:"90px"}}/>
          <h3 className="mb-0">Quran Learning</h3>
          {/* Icon usage */}
          
          {/* Example of commented out content */}
          <p className="mb-0" style={{color:"black"}}>Learning the essentials of Quran at Masjid Zakaria.</p>
          <Link to="/services-detail-quranclasses" title="" style={{color:"black"}}>Read More</Link>
        </div>
      </Link>
    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-110 black-layer pb-70 opc7 position-relative">
                    <div class="fixed-bg" style={{backgroundImage: `url(${Naat})`}}></div>
                    <div class="container">
                        <div class="sec-title v2 text-center w-100">
                            <div class="sec-title-inner d-inline-block">
                                <i class="flaticon-rub-el-hizb thm-clr"></i>
                                <h1 class="mb-0">The Pillars of Islam</h1>
                                <p class="mb-0"></p>
                            </div>
                        </div>
                        <div class="pillars-wrap w-100">
                            <ul class="pillars-list text-center d-flex flex-wrap justify-content-center align-items-center mb-0 list-unstyled">
                                <li>
                                    <div class="pillar-box position-relative w-100">
                                        <span class="d-inline-block thm-clr position-relative"><span class="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}></span><i class="flaticon-quran-1" style={{backgroundColor:"green"}}></i></span>
                                        <h3 class="mb-0">Shahadah</h3>
                                        <i class="d-block thm-clr">Faith</i>
                                    </div>
                                </li>
                                <li>
                                    <div class="pillar-box position-relative w-100">
                                        <span class="d-inline-block thm-clr position-relative"><span class="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}></span><i class="flaticon-mosque-1" style={{backgroundColor:"green"}}></i></span>
                                        <h3 class="mb-0">Salah</h3>
                                        <i class="d-block thm-clr">Prayer</i>
                                    </div>
                                </li>
                                <li>
                                    <div class="pillar-box position-relative w-100">
                                        <span class="d-inline-block thm-clr position-relative"><span class="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg" style={{backgroundImage: "url(images1/pattern-bg.jpg)"}}></span><i class="flaticon-star" style={{backgroundColor:"green"}}></i></span>
                                        <h3 class="mb-0">Sawm</h3>
                                        <i class="d-block thm-clr">Fasting</i>
                                    </div>
                                </li>
                                <li>
                                    <div class="pillar-box position-relative w-100">
                                        <span class="d-inline-block thm-clr position-relative"><span class="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}></span><i class="flaticon-gift-box" style={{backgroundColor:"green"}}></i></span>
                                        <h3 class="mb-0">Zakat</h3>
                                        <i class="d-block thm-clr">Almsgiving</i>
                                    </div>
                                </li>
                                <li>
                                    <div class="pillar-box position-relative w-100">
                                        <span class="d-inline-block thm-clr position-relative"><span class="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg" style={{backgroundImage: "url(assets/images/pattern-bg.jpg)"}}></span><i class="flaticon-kaaba" style={{backgroundColor:"green"}}></i></span>
                                        <h3 class="mb-0">Hajj</h3>
                                        <i class="d-block thm-clr">Pilgrimage</i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
      </>
  );
};

export default SeeratComponent;
