import React from "react";
import { useState,useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./SundayBayan.css"
import Logo1 from "../../assets/audio/images/logo185.png"
import Logo2 from "../../assets/audio/images/logo1851.png"
import Mosqueimg from "../../assets/audio/images/mosqueaudio.jpg"
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
const SundayBayanComponent = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [preloaderVisible, setPreloaderVisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const bayansPerPage = 12;
    const handleMenuBtnClick = () => {
        const menu = document.querySelector('.rsnp-mnu');
        menu.classList.add('slidein');
      };
    
      const handleMenuCloseClick = () => {
        const menu = document.querySelector('.rsnp-mnu');
        menu.classList.remove('slidein');
      };
    
      const handleMenuItemClick = (event) => {
        const menuItem = event.currentTarget.parentElement;
        const siblings = Array.from(menuItem.parentNode.children).filter((child) => child !== menuItem);
      
        siblings.forEach((sibling) => {
          const ul = sibling.querySelector('ul');
          if (ul) {
            ul.style.display = 'none';
          }
          sibling.classList.remove('active');
        });
      
        const childUl = menuItem.querySelector('ul');
        if (childUl) {
          if (childUl.style.display === 'block') {
            childUl.style.display = 'none';
          } else {
            childUl.style.display = 'block';
          }
        }
      
        menuItem.classList.toggle('active');
      };

      const [sunriseTime, setSunriseTime] = useState('');
      const [sunsetTime, setSunsetTime] = useState('');
    const [fridayBayans, setFridayBayans] = useState([]);
    const [bayantCount, setBayantCount] = useState(0);
    useEffect(() => {
      const fetchFridayBayans = async () => {
        const currentDate = new Date().toISOString().split('T')[0];
        const apisunset = `https://api.sunrisesunset.io/json?lat=24.9331712&lng=67.1055872&date=${currentDate}`;
        
        try {
          const response1 = await axios.get(`${apiUrl}/get-recordings-count/morningdars`);
          setBayantCount(response1.data.bayantCount);
          
          // Fetch Friday Bayans
          const response = await axios.get(`${apiUrl}/get-recordings/morningdars`, {
            params: {
              page: pageNumber // Make sure pageNumber is defined and accessible here
            }
          });
          setFridayBayans(response.data);
          setPreloaderVisible(false);
          
    
          // Fetch sunrise and sunset times
          axios.get(apisunset)
            .then(response => {
              const { sunrise, sunset } = response.data.results;
              setSunriseTime(sunrise);
              setSunsetTime(sunset);
            })
            .catch(error => {
              console.error('Error fetching sunrise and sunset times:', error.message);
            });
        } catch (error) {
          setPreloaderVisible(false);
          console.error('Error fetching Friday bayans:', error.message);
        }
      };
    
      fetchFridayBayans();
    }, [apiUrl, pageNumber]); // Add dependencies to useEffect

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pageNumber]);    
    // Function to handle pagination
    const paginate = pageNumber => {
      setPreloaderVisible(true)
      setPageNumber(pageNumber);
    }
  return (
    <>
    <Helmet>
                <meta
                    name="description"
                    content="Explore the latest Morning bayanats from our mosque. Listen to inspiring bayans delivered daily, available for easy accessibility on our website."
                />
                <title>Latest Morning Bayans | Mosque Audio</title>
            </Helmet>
    {preloaderVisible && (
     <div id="preloader">            
                <div className="preloader-inner">
                    <i className="preloader-icon thm-clr flaticon-kaaba"></i>
                </div>
            </div>
            )}
            <header className="stick style1 w-100">
                <div className="topbar bg-color1 d-flex flex-wrap justify-content-center w-100">
                    <ul className="topbar-info-list mb-0 list-unstyled d-inline-flex">
                        <li><i className="thm-clr flaticon-sun" style={{color:"yellow"}}></i>Sunrise At: <span className="thm-clr">{sunriseTime}</span></li>
                        <li><i className="thm-clr flaticon-moon" style={{color:"blue"}}></i>Sunset At: <span className="thm-clr">{sunsetTime}</span></li>
                    </ul>
                    <div className="social-links d-inline-flex">
                        <Link to="https://www.facebook.com/JamaMasjidZakariya" title="Facebook" ><i className="fab fa-facebook-f"></i></Link>
                    </div>
                </div>
                
                <div className="logo-menu-wrap d-flex flex-wrap justify-content-between w-100" style={{backgroundColor:"black"}}>
                    <div className="logo position-relative thm-layer opc7 back-blend-multiply thm-bg" >
                        <h1 className="mb-0">
                            <Link to="/" title="Home">
                            <img className="img-fluid" src={Logo2} alt="Logo" srcset={Logo2}/>
                            </Link></h1></div>
                    <nav className="d-flex flex-wrap align-items-center justify-content-end ml-auto">
                        <div className="header-left">
                            <ul className="mb-0 list-unstyled d-inline-flex">
                               
                            <li ><Link to="/" title="" style={{color:"white"}}>Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="" style={{color:"white"}}>Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="">Bayan</Link></li>
                                            <li><Link to="/naat" title="">Naat</Link></li>
                                            <li><Link to="/seerat" title="">Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="" style={{color:"white"}}>Activities</Link></li>
                                    <li><Link to="/services" style={{color:"white"}} title="">Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="" style={{color:"white"}}>Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="" style={{color:"white"}}>About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="">About Mosque</Link></li>
                                            <li><Link to="/contact" title="">Contact Us</Link></li>
                                        </ul>
                                    </li>
                            </ul>
                        </div>
                        <div className="header-right">
                            <Link className="thm-btn thm-bg" to="/login" title="">Login<span></span><span></span><span></span><span></span></Link>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="sticky-menu">
                <div className="container">
                    <div className="sticky-menu-inner d-flex flex-wrap align-items-center justify-content-between w-200">
                        <div className="logo"><h1 className="mb-0">
                            <Link to="/" title="Home">
                            <img classNameName="img-fluid" src={Logo2} alt="Logo" srcset={Logo2} /></Link></h1></div>
                        <nav className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="header-left">
                                <ul className="mb-0 list-unstyled d-inline-flex">
                                <li ><Link to="/" title="">Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="">Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="">Bayan</Link></li>
                                            <li><Link to="/naat" title="">Naat</Link></li>
                                            <li><Link to="/seerat" title="">Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="">Activities</Link></li>
                                    <li><Link to="/services" title="">Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="">Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="">About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="">About Mosque</Link></li>
                                            <li><Link to="/contact" title="">Contact Us</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="rspn-hdr" style={{backgroundColor:"black"}}>
            <div className="rspn-mdbr" style={{ height: "40px" }}>
      <div className="rspn-scil" style={{ fontSize: "14px", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
        <span style={{marginRight:"20px", color:"peachpuff"}}><i className="fas fa-sun" style={{color:"yellow"}}></i> Sunrise: {sunriseTime}</span>
        <span style={{color:"peachpuff"}} ><i className="fas fa-moon" style={{color:"blue"}}></i> Sunset: {sunsetTime}</span>
      </div>
    </div>
                <div className="lg-mn">
                    <div className="logo"><Link to="/" title="Home"><img src={Logo1} alt="Logo"/></Link></div>
                    <div className="rspn-cnt">
                        <span><i className="thm-clr far fa-envelope"></i><Link to="" title="">infozakariamasjid@gmail.com</Link></span>
                        <span><i className="thm-clr fas fa-phone-alt"></i>+92 321 8259780</span>
                    </div>
                    <span className="rspn-mnu-btn" onClick={handleMenuBtnClick}><i className="fa fa-list-ul"></i></span>
                </div>
                <div className="rsnp-mnu">
                    <span className="rspn-mnu-cls" onClick={handleMenuCloseClick}><i className="fa fa-times"></i></span>
                    <ul className="mb-0 list-unstyled w-100">
                    <li ><Link to="/" title="">Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="" onClick={handleMenuItemClick}>Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="">Bayan</Link></li>
                                            <li><Link to="/naat" title="">Naat</Link></li>
                                            <li><Link to="/seerat" title="">Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="">Activities</Link></li>
                                    <li><Link to="/services" title="">Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="">Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="" onClick={handleMenuItemClick}>About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="">About Mosque</Link></li>
                                            <li><Link to="/contact" title="">Contact Us</Link></li>
                                        </ul>
                                    </li>
                    </ul>
                </div>
            </div>
     <section>
                <div className="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div className="fixed-bg" style={{backgroundImage:`url(${Mosqueimg})`}}></div>
                    <div className="container">
                        <div className="page-title-wrap text-center w-100">
                            <div className="page-title-inner d-inline-block">
                                <h1 className="mb-0">Listen To Bayan</h1>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/" title="Home">Masjid - Home</Link></li>
                                    <li className="breadcrumb-item active">Audio Listening</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
            <div className="w-100 pt-120 pb-250 position-relative">
        <img
          className="sec-botm-rgt-mckp img-fluid position-absolute"
          src="images1/sec-botm-mckp.png"
          alt="Sec Bottom Mockup"
        />
        <div className="container">
          {/* <div className="plyr-box w-100 pat-bg opc85 back-blend-multiply bggreen"> */}
            <div className="plyr v4 w-100">
              {fridayBayans.map((bayan, index) => (
                <div key={index} className="audio-container" style={{ border: "2px solid black", backgroundColor:"white", color:"black", marginBottom:"15px" }}>
                     <div className="audio-info" style={{display:"flex"}}>
                     <p className="audio-title" style={{ color: "black", fontWeight: "bold", fontSize: "16px" }}>Topic:{ " "}{bayan.title}</p>
                    <p className="audio-title" style={{ color: "black", fontWeight: "bold", fontSize: "16px", marginLeft: "8px" }}>Date: {bayan.date}</p>

                  </div>
                  <ReactPlayer
                    url={encodeURI(bayan.downloadURL)}
                    controls
                    width="100%"
                    height="50px" 
                  />
                 
                </div>
              ))}
            </div>             
                    </div>
                    
                    {fridayBayans.length > 0 && (
  <ul className="pagination" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", padding: 0, listStyleType: "none" }}>
    <li className="page-item" style={{ marginRight: "50px" }}>
      <button style={{ backgroundColor: "black", color: "white" }}
        className="page-link"
        onClick={() => paginate(pageNumber - 1)} // Go to the previous page
        disabled={pageNumber === 1} // Disable if on the first page
      >
        Previous
      </button>
    </li>
    {Array.from({ length: Math.ceil(bayantCount / bayansPerPage) }, (_, index) => index + 1).map((number, index, arr) => {
      if (number === 1 || number === arr.length || (number >= pageNumber - 1 && number <= pageNumber + 1)) {
        return (
          <li key={index} className={`page-item ${pageNumber === number ? 'active' : ''}`} style={{ margin: "5px" }}>
            <button
              style={{
                backgroundColor: pageNumber === number ? "blue" : "black",
                color: "white"
              }}
              onClick={() => paginate(number)}
              className="page-link"
            >
              {number}
            </button>
          </li>
        );
      } else if ((number === 2 && pageNumber > 3) || (number === arr.length - 1 && pageNumber < arr.length - 2)) {
        return <li key={index} style={{ marginRight:"20px", border: "2px solid black", padding: "4px 8px" }}>...</li>;
      } else {
        return null;
      }
    })}
    <li className="page-item" style={{ margin: "5px" }}>
      <button style={{ backgroundColor: "black", color: "white" }}
        className="page-link"
        onClick={() => paginate(pageNumber + 1)} // Go to the next page
        disabled={pageNumber === Math.ceil(bayantCount / bayansPerPage)} // Disable if on the last page
      >
        Next
      </button>
    </li>
  </ul>
)}







                </div>
                
               
            </section>
            
    </>
  );
};

export default SundayBayanComponent;
