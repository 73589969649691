import { Link } from "react-router-dom"
import Morning from "../../assets/audio/images/morningdars.jpg"
// import Logo1 from "../../assets/audio/images/logo185.png"
import NavbarAllFiles from "../NavbarAll/NavbarAllFiles";
const Contact = () => {
   
      
  return (
  <>
   <NavbarAllFiles/>
   <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundImage: `url(${Morning})`}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">Contact Us</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><Link to="/" title="Home">Masjid - Home</Link></li>
                                    <li class="breadcrumb-item active">Contact Us</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-90 thm-layer opc6 position-relative">
                    <div class="fixed-bg patern-bg back-blend-multiply thm-bg" style={{backgroundImage: "url(images1/pattern-bg.jpg)"}}></div>
                    <div class="container">
                        <div class="cont-info-wrap res-row overlap-115 w-100">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="cont-info-box-wrap w-100">
                                        <div class="cont-info-box text-center position-relative w-100 pat-bg white-layer opc8 back-blend-multiply bg-white" style={{backgroundImage: "url(images1/pattern-bg.jpg)"}}>
                                            <span class="bg-color1"><i class="flaticon-call"></i></span>
                                            <h4 class="mb-0">Whatsapp Number</h4>
                                            <p class="mb-0">+92 321 8259780</p>
                                            <p class="mb-0"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="cont-info-box-wrap w-100">
                                        <div class="cont-info-box text-center position-relative w-100 pat-bg white-layer opc8 back-blend-multiply bg-white" style={{backgroundImage: "url(images1/pattern-bg.jpg)"}}>
                                            <span class="bg-color1"><i class="flaticon-mail"></i></span>
                                            <h4 class="mb-0">Email Address</h4>
                                            <p class="mb-0">infozakariamasjid@gmail.com</p>
                                            <p class="mb-0"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-4">
                                    <div class="cont-info-box-wrap w-100">
                                        <div class="cont-info-box text-center position-relative w-100 pat-bg white-layer opc8 back-blend-multiply bg-white" style={{backgroundImage: "url(images1/pattern-bg.jpg)"}}>
                                            <span class="bg-color1"><i class="fas fa-map-marker-alt"></i></span>
                                            <h4 class="mb-0">Location</h4>
                                            <p class="mb-0">Khayaban-e-Khalid &, 109 26th Street, Phase 8 Defence Housing Authority, Karachi City , Sindh 75500
</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                  
                </div>
               
            </section>
            <section>
           
                <div class="w-100 pb-250 position-relative" >
                
                    <img class="sec-botm-rgt-mckp img-fluid position-absolute" src="images1/sec-botm-mckp.png" alt="Sec Bottom Mockup"/>
                    <div id="contact-map" class="contact-map w-100"></div>
                    <div class="container">
                    <iframe style={{top:"-100px"}}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22540.60020804509!2d67.07084322914714!3d24.781052385086497!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d893d846dd1%3A0x44dbcba7bdf58cc7!2sJama%20Masjid%20Zakariya!5e0!3m2!1sen!2s!4v1694978909193!5m2!1sen!2s"
            title="masjid-Zakariya"
            width="100%"
            height="600px"
            loading="lazy"
          ></iframe>
                        <div class="contact-wrap mt-100 w-100">
    <div class="row">
        <div class="col-md-6 col-sm-12 col-lg-6">
            <div class="cont-info-desc w-100">
                <h3 class="mb-0">Have any Questions? Fill up the form with your query</h3>
                <p class="mb-0"></p>
                <h6 class="mb-0">Follow us on social media</h6>
                <div class="social-links2 v2 text-center d-inline-flex">
                    <Link class="facebook" to="https://www.facebook.com/JamaMasjidZakariya" title="Facebook" ><i class="fab fa-facebook-f"></i></Link>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6">
            
        <iframe
  src="https://forms.gle/oYXzj7H2oQotagvA9"
  title="Google Form - Contact Us"
  width="100%"
  height="800"
  frameborder="0"
  marginheight="0"
  marginwidth="0"
>
  Loading…
</iframe>
        </div>
    </div>
</div>
                    </div>
                </div>
            </section>

       
      
  </>
  )
}

export default Contact
