import React from 'react'
import "./navbar.css";
import axios from 'axios';
import { useEffect, useState } from 'react';
import Logo1 from "../../assets/audio/images/logo185.png"
import Logo2 from "../../assets/audio/images/logo1851.png"
import { Link } from 'react-router-dom';
const NavbarAll = () => {
    const [preloaderVisible, setPreloaderVisible] = useState(true);

    const handleMenuBtnClick = () => {
        const menu = document.querySelector('.rsnp-mnu');
        menu.classList.add('slidein');
      };
    
      const handleMenuCloseClick = () => {
        const menu = document.querySelector('.rsnp-mnu');
        menu.classList.remove('slidein');
      };
    
      const handleMenuItemClick = (event) => {
        const menuItem = event.currentTarget.parentElement;
        const siblings = Array.from(menuItem.parentNode.children).filter((child) => child !== menuItem);
      
        siblings.forEach((sibling) => {
          const ul = sibling.querySelector('ul');
          if (ul) {
            ul.style.display = 'none';
          }
          sibling.classList.remove('active');
        });
      
        const childUl = menuItem.querySelector('ul');
        if (childUl) {
          if (childUl.style.display === 'block') {
            childUl.style.display = 'none';
          } else {
            childUl.style.display = 'block';
          }
        }
      
        menuItem.classList.toggle('active');
      };
    
  
      const [sunriseTime, setSunriseTime] = useState('');
      const [sunsetTime, setSunsetTime] = useState('');
    
      useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        const apiUrl = `https://api.sunrisesunset.io/json?lat=24.9331712&lng=67.1055872&date=${currentDate}`;
        // Your existing useEffect code
        // Example: Set preloader visibility to false
        // console.log(currentDate);
        try {
          setPreloaderVisible(false);
        //   console.log("Seerat data fetched successfully");
        } catch (error) {
          setPreloaderVisible(false);
        //   console.error('Error fetching Seerat data:', error.message);
        }
        
        // Fetch sunrise and sunset times
        axios.get(apiUrl)
          .then(response => {
            const { sunrise, sunset } = response.data.results;
            setSunriseTime(sunrise);
            setSunsetTime(sunset);
          })
          .catch(error => {
            console.error('Error fetching sunrise and sunset data:', error);
          });
      }, []);
    const handleLinkClick = () => {
      
        window.scrollTo(0, 0);
      };
  return (
    <>
    {preloaderVisible && (
         <div id="preloader">            
                <div class="preloader-inner">
                    <i class="preloader-icon thm-clr flaticon-kaaba"></i>
                </div>
            </div>
             )}
            <header class="stick style2 w-100">
                <div class="topbar bg-color1 d-flex flex-wrap justify-content-between w-100">
                	<div class="topbar-left">
                		<ul class="topbar-info-list mb-0 list-unstyled d-inline-flex">
	                        <li><i class="thm-clr far fa-envelope"></i><Link to=" " title="">infozakariamasjid@gmail.com</Link></li>
	                        <li><i class="thm-clr fas fa-phone-alt"></i>+92 321 8259780</li>
	                    </ul>
                	</div>
                    <div class="topbar-right d-inline-flex">
                    	<ul class="topbar-info-list mb-0 list-unstyled d-inline-flex">
	                        <li><i class="thm-clr flaticon-sun" style={{color:"yellow"}}></i>Sunrise At: <span class="thm-clr">{sunriseTime}</span></li>
	                        <li><i class="thm-clr flaticon-moon" style={{color:"blue"}}></i>Sunset At: <span class="thm-clr">{sunsetTime}</span></li>
	                    </ul>
	                    <div class="social-links d-inline-flex">
	                        <Link to="https://www.facebook.com/JamaMasjidZakariya" title="Facebook"><i class="fab fa-facebook-f"></i></Link>
	                    </div>
                    </div>
                </div>
                <div class="logo-menu-wrap v2 d-flex flex-wrap align-items-center justify-content-between w-100 pat-bg  "style={{backgroundColor:"black"}}>
                    <div class="logo"><h1 class="mb-0"><Link to="/" title="Home"><img class="img-fluid" src={Logo1} alt="Logo" srcset={Logo1}/></Link></h1></div>
                    <nav class="d-flex flex-wrap align-items-center justify-content-between">
                        <div class="header-left">
                        	<ul class="mb-0 list-unstyled d-inline-flex">
                            <li ><Link to="/" title="" style={{color:"white"}}>Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="" style={{color:"white"}}>Spirituality & Bayanat</Link>
                                    <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="" onClick={handleLinkClick}>Bayan</Link></li>
                                            <li><Link to="/naat" title="" onClick={handleLinkClick}>Naat</Link></li>
                                            <li><Link to="/seerat" title="" onClick={handleLinkClick}>Books</Link></li>
                                        </ul>
                                    </li>
                                <li><Link to="/activities" title="" style={{color:"white"}} onClick={handleLinkClick}>Activities</Link></li>
                                <li><Link to="/services" title="" style={{color:"white"}} onClick={handleLinkClick}>Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="" style={{color:"white"}}>Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="" style={{color:"white"}}>About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="" onClick={handleLinkClick}>About Mosque</Link></li>
                                            <li><Link to="/contact" title="" onClick={handleLinkClick}>Contact Us</Link></li>
                                        </ul>
                                    </li>
                            </ul>
                        </div>
                        <div class="header-right">
                            <Link class="thm-btn bg-color1" to="/login" title="" onClick={handleLinkClick}>Login<span></span><span></span><span></span><span></span></Link>
                        </div>
                    </nav>
                </div>
            </header>
            <div class="sticky-menu">
                <div class="container">
                    <div class="sticky-menu-inner d-flex flex-wrap align-items-center justify-content-between w-100">
                        <div class="logo"><h1 class="mb-0"><Link to="/" title="Home"><img class="img-fluid" src={Logo2} alt="Logo" srcset={Logo2}/></Link></h1></div>
                        <nav class="d-flex flex-wrap align-items-center justify-content-between">
                            <div class="header-left">
                                <ul class="mb-0 list-unstyled d-inline-flex">
                                <li ><Link to="/" title="" onClick={handleLinkClick}>Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="">Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="" onClick={handleLinkClick}>Bayan</Link></li>
                                            <li><Link to="/naat" title="" onClick={handleLinkClick}>Naat</Link></li>
                                            <li><Link to="/seerat" title="" onClick={handleLinkClick}>Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="" onClick={handleLinkClick}>Activities</Link></li>
                                    <li><Link to="/services" title="" onClick={handleLinkClick}>Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="">Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="">About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="" onClick={handleLinkClick}>About Mosque</Link></li>
                                            <li><Link to="/contact" title="" onClick={handleLinkClick}>Contact Us</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="rspn-hdr" style={{backgroundColor:"black"}}>
            <div className="rspn-mdbr" style={{ height: "40px" }}>
      <div className="rspn-scil" style={{ fontSize: "14px", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
        <span style={{marginRight:"20px", color:"peachpuff"}}><i className="fas fa-sun" style={{color:"yellow"}}></i> Sunrise: {sunriseTime}</span>
        <span style={{color:"peachpuff"}}><i className="fas fa-moon" style={{color:"blue"}}></i> Sunset: {sunsetTime}</span>
      </div>
    </div>
                <div class="lg-mn">
                    <div class="logo"><Link to="/" title="Home" onClick={handleLinkClick}><img src={Logo1} alt="Logo"/></Link></div>
                    <div class="rspn-cnt">
                        <span><i class="thm-clr far fa-envelope"></i><Link to=" " title="">infozakariamasjid@gmail.com</Link></span>
                        <span><i class="thm-clr fas fa-phone-alt"></i>+92 321 8259780</span>
                    </div>
                    <span class="rspn-mnu-btn" onClick={handleMenuBtnClick}><i class="fa fa-list-ul" onClick={handleMenuBtnClick}></i></span> 
                </div>
                <div class="rsnp-mnu">
                    <span class="rspn-mnu-cls" onClick={handleMenuCloseClick}><i class="fa fa-times" onClick={handleMenuCloseClick}></i></span>
                    <ul class="mb-0 list-unstyled w-100">
                    <li ><Link to="/" title="" onClick={handleLinkClick}>Home</Link>
                                    </li>
                                    <li className="menu-item-has-children"><Link to="" title="" onClick={handleMenuItemClick}>Spirituality & Bayanat</Link>
                                        
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link  to="/hazrat" title="" onClick={handleLinkClick}>Bayan</Link></li>
                                            <li><Link to="/naat" title="" onClick={handleLinkClick}>Naat</Link></li>
                                            <li><Link to="/seerat" title="" onClick={handleLinkClick}>Books</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/activities" title="" onClick={handleLinkClick}>Activities</Link></li>
                                    <li><Link to="/services" title="" onClick={handleLinkClick}>Services</Link></li>

                                    {/* <li className="/blog"><Link to="/blog" title="">Blog</Link>
                                    </li> */}
                                    <li className="menu-item-has-children"><Link to="" title="" onClick={handleMenuItemClick}>About Us</Link>
                                        <ul className="mb-0 list-unstyled">
                                            <li><Link to="/about" title="" onClick={handleLinkClick}>About Mosque</Link></li>
                                            <li><Link to="/contact" title="" onClick={handleLinkClick}>Contact Us</Link></li>
                                        </ul>
                                    </li>
                    </ul>
                </div>
            </div>
    </>
       
     
  )
}

export default NavbarAll
