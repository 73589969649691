import React from 'react'
import NavbarAllFiles from '../NavbarAll/NavbarAllFiles'
// import DonationBox from "../../assets/audio/images/donationbox.jpg"
const ServicesDetailHelpingPoor = () => {
  return (
    <>
    <NavbarAllFiles/>
    <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundColor:""}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">Services Detail</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><a href="/" title="Home">Maktab - Home</a></li>
                                    <li class="breadcrumb-item"><a href="/services" title="">Services</a></li>
                                    <li class="breadcrumb-item active">Detail</li>
                                    <li class="breadcrumb-item active">Funeral</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-120 pb-250 position-relative">
                   
                    <div class="container">
                        <div class="services-detail-wrap w-100">
                            <div class="services-detail-info-wrap w-100">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-lg-6 order-md-1">
                                        <div class="service-detail-img position-relative w-100">
                                            {/* <img class="img-fluid w-100" src={DonationBox} alt="Service Detai"/> */}
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-lg-6">
                                        <div class="services-detail-info w-100">
                                            <i class="rounded-circle d-inline-block thm-bg flaticon-quran-2"></i>
                                            <h2 class="mb-0">Helping Poor</h2>
                                            <span class="d-block"></span>
                                            <ul class="services-info-list mb-0 list-unstyled">
                                                {/* <li>Recognition, drive special offers</li>
                                                <li>Launch a new product or communicate</li>
                                                <li>Message and generate action</li>
                                                <li>Campaign to powerfully convey</li>
                                                <li>Outdoor media, PR and digital marketing</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="services-detail-desc w-100">
                                <p class="mb-0">Masjid Zakaria actively participates in helping the poor and needy of the society. We have partnered with welfare organizations and together we are committed in helping the poor.</p>
                                <p class="mb-0">Current Projects we are working at:</p>
                                <ul class="services-info-list mb-0 list-unstyled">
                                                <li>Sasti Roti</li>
                                                <li>Food Bank</li>
                                                <li>Medical Assistance</li>
                                                <li>Shelter and Clothing</li>

                                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
   
    </>
  )
}

export default ServicesDetailHelpingPoor
