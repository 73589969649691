import React from 'react'
import NavbarAllFiles from '../NavbarAll/NavbarAllFiles'
import MosqueDev from "../../assets/audio/images/mosquedev.jpeg"
const ServicesDetailMosqueDev = () => {
  return (
    <>
    <NavbarAllFiles/>
    <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundColor:""}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">Services Detail</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><a href="/" title="Home">Maktab - Home</a></li>
                                    <li class="breadcrumb-item"><a href="/services" title="">Services</a></li>
                                    <li class="breadcrumb-item active">Detail</li>
                                    <li class="breadcrumb-item active">Funeral</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-120 pb-250 position-relative">
                   
                    <div class="container">
                        <div class="services-detail-wrap w-100">
                            <div class="services-detail-info-wrap w-100">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-lg-6 order-md-1">
                                        <div class="service-detail-img position-relative w-100">
                                            <img class="img-fluid w-100" src={MosqueDev} alt="Service Detai"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-lg-6">
                                        <div class="services-detail-info w-100">
                                            <i class="rounded-circle d-inline-block thm-bg flaticon-quran-2"></i>
                                            <h2 class="mb-0">Mosque Development</h2>
                                            <span class="d-block"></span>
                                            <ul class="services-info-list mb-0 list-unstyled">
                                                {/* <li>Recognition, drive special offers</li>
                                                <li>Launch a new product or communicate</li>
                                                <li>Message and generate action</li>
                                                <li>Campaign to powerfully convey</li>
                                                <li>Outdoor media, PR and digital marketing</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="services-detail-desc w-100">
                                <p class="mb-0">We are pleased to extend an open invitation to all members of our community to participate in the development and growth of new Mosques over all Pakistan. Your involvement and support are integral to our efforts in enhancing our mosque facilities and services to better serve the needs of our community.</p>
                                <p class="mb-0">Opportunities for Participation:</p>
                                <ul class="services-info-list mb-0 list-unstyled">
                                                <li><b>Financial Contributions:</b>

Your financial donations are crucial in funding various development projects aimed at improving the infrastructure, facilities.
Donations of any amount are welcome and greatly appreciated. Every contribution, no matter the size, makes a meaningful impact on new mosque development.</li>
                                                <li><b>Volunteerism:</b>

We welcome volunteers who are willing to dedicate their time, skills, and expertise towards various development initiatives.
Opportunities for volunteering include assisting in renovation projects, organizing community events, providing administrative support, and more.</li>
                                                <li><b>Professional Services:</b>

If you possess professional skills or expertise in areas such as architecture, construction, engineering, fundraising, event management, or marketing, we invite you to lend your talents to our mosque development efforts.</li>
                                            

                                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
   
    </>
  )
}

export default ServicesDetailMosqueDev
