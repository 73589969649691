import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Praying from "../../assets/audio/images/praying2.jpg"
import Hero from "../../assets/audio/images/masjid1.jpg"
const Blog = () => {
    const handleLinkClick = () => {
      
        window.scrollTo(0, 0);
      };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
     <div class={`container-fluid fixed-top ${scrolled ? 'scrolled' : ''}`}>
            <div class="container topbar d-none d-lg-block">
                <div class="topbar-inner">
                    <div class="row gx-0">
                        <div class="col-lg-7 text-start">
                            <div class="h-100 d-inline-flex align-items-center me-4">
                                <span class="fa fa-phone-alt me-2 text-dark"></span>
                                <Link to="" class="text-secondary"><span>+012 345 6789</span></Link>
                            </div>
                            <div class="h-100 d-inline-flex align-items-center">
                                <span class="far fa-envelope me-2 text-dark"></span>
                                <Link to="" class="text-secondary"><span>info@example.com</span></Link>
                            </div>
                        </div>
                        <div class="col-lg-5 text-end">
                            <div class="h-100 d-inline-flex align-items-center">
                                <span class="text-body">Follow Us:</span>
                                {/* <Link class="text-dark px-2" to=""><i class="fab fa-facebook-f"></i></Link>
                                <Link class="text-dark px-2" to=""><i class="fab fa-twitter"></i></Link>
                                <Link class="text-dark px-2" to=""><i class="fab fa-linkedin-in"></i></Link>
                                <Link class="text-dark px-2" to=""><i class="fab fa-instagram"></i></Link>
                                <Link class="text-body ps-4" to=""><i class="fa fa-lock text-dark me-1"></i> Signup/login</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <nav class="navbar navbar-light navbar-expand-lg py-3">
                    <Link to="index.html" class="navbar-brand">
                        <h1 class="mb-0">Jama Masjid<span class="orangebgtext">{" "}Zakaria</span> </h1>
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="fa fa-bars text-primary"></span>
                    </button>
                    <div class="collapse navbar-collapse bg-white" id="navbarCollapse">
                        <div class="navbar-nav ms-lg-auto mx-xl-auto">
                            <Link onClick={handleLinkClick} to="/" class="nav-item nav-link ">Home</Link>
                            <div class="nav-item dropdown">
                                <Link to="/" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Spirituality & Bayanat</Link>
                                <div class="dropdown-menu m-0 rounded-0">
                                    <Link onClick={handleLinkClick} to="/hazrat" class="dropdown-item">Bayan</Link>
                                    <Link onClick={handleLinkClick} to="/naat" class="dropdown-item">Naat</Link>
                                    <Link  onClick={handleLinkClick} to="/seerat" class="dropdown-item">Books</Link>
                                    
                                </div>
                            </div>
                            <Link onClick={handleLinkClick} to="/activities" class="nav-item nav-link ">Activities</Link>
                            <Link onClick={handleLinkClick} to="/blog" class="nav-item nav-link active">Blog</Link>
                            <div class="nav-item dropdown">
                            <Link onClick={handleLinkClick} to="/" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">About Us</Link>
                            <div class="dropdown-menu m-0 rounded-0">
                            <Link onClick={handleLinkClick} to="/about" class="nav-item nav-link">About Mosque</Link>
                            <Link onClick={handleLinkClick} to="/services" class="nav-item nav-link">Services</Link>
                            <Link onClick={handleLinkClick} to="/contact" class="nav-item nav-link">Contact Us</Link>
                            </div>
                            </div>
                            {/* <div class="nav-item dropdown">
                                <Link to="/" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                                <div class="dropdown-menu m-0 rounded-0">
                                    <Link to="/" class="dropdown-item">Latest Blog</Link>
                                    <Link to="/" class="dropdown-item">Our Team</Link>
                                    <Link to="/" class="dropdown-item">Testimonial</Link>
                                    <Link to="/" class="dropdown-item">404 Page</Link>
                                </div>
                            </div> */}
                            {/* <Link to="/" class="nav-item nav-link">Contact</Link> */}
                        </div>
                        <Link to="/login" class="btn py-2 px-4 d-none d-xl-inline-block orangebg">Login</Link>
                    </div>
                </nav>
            </div>
        </div>
       
        <div class="container-fluid hero-header">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="hero-header-inner animated zoomIn">
                            <h1 class="display-1 text-dark">Our Blogs</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  style={{ marginTop: '-50px' }}>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1 style={{color:'black'}} className="display-3 mb-5 wow fadeIn" data-wow-delay="0.1s">
            Latest From <span style={{color:"orange"}}>Our Blog</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-6 col-xl-4">
              <div className="blog-item wow fadeIn" data-wow-delay="0.1s">
                <div className="blog-img position-relative overflow-hidden">
                  <img src={Praying} className="img-fluid w-100" alt="" />
                  <div className="orangebg d-inline px-3 py-2 text-center text-white position-absolute top-0 end-0">
                    01 Jan 2045
                  </div>
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2">
                    <div>
                      <small>
                        <i className="fas fa-user me-2 text-muted"></i>
                        <Link to="" className="text-muted me-2">
                          By Admin
                        </Link>
                      </small>
                    </div>
                    <div>
                      <Link to="">
                        <i className="fas fa-bookmark text-muted"></i>
                      </Link>
                    </div>
                  </div>
                  <Link to="" className="d-inline-block h4 lh-sm mb-3">
                    Importance of “Piller” of Islam
                  </Link>
                  <p style={{color:'black'}} className="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, aliquip ex ea commodo consequat.
                  </p>
                  <Link to="#" className="btn orangebg px-3">
                    More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="blog-item wow fadeIn" data-wow-delay="0.1s">
                <div className="blog-img position-relative overflow-hidden">
                  <img src={Hero} className="img-fluid w-100" alt="" />
                  <div className="orangebg d-inline px-3 py-2 text-center text-white position-absolute top-0 end-0">
                    01 Jan 2045
                  </div>
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2">
                    <div>
                      <small>
                        <i className="fas fa-user me-2 text-muted"></i>
                        <Link to="" className="text-muted me-2">
                          By Admin
                        </Link>
                      </small>
                    </div>
                    <div>
                      <Link to="">
                        <i className="fas fa-bookmark text-muted"></i>
                      </Link>
                    </div>
                  </div>
                  <Link to="" className="d-inline-block h4 lh-sm mb-3">
                    Importance of “Piller” of Islam
                  </Link>
                  <p style={{color:'black'}} className="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, aliquip ex ea commodo consequat.
                  </p>
                  <Link to="#" className="btn orangebg px-3">
                    More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="blog-item wow fadeIn" data-wow-delay="0.1s">
                <div className="blog-img position-relative overflow-hidden">
                  <img src={Praying} className="img-fluid w-100" alt="" />
                  <div className="orangebg d-inline px-3 py-2 text-center text-white position-absolute top-0 end-0">
                    01 Jan 2045
                  </div>
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2">
                    <div>
                      <small>
                        <i className="fas fa-user me-2 text-muted"></i>
                        <Link to="" className="text-muted me-2">
                          By Admin
                        </Link>
                      </small>
                    </div>
                    <div>
                      <Link to="">
                        <i className="fas fa-bookmark text-muted"></i>
                      </Link>
                    </div>
                  </div>
                  <Link to="" className="d-inline-block h4 lh-sm mb-3">
                    Importance of “Piller” of Islam
                  </Link>
                  <p style={{color:'black'}} className="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, aliquip ex ea commodo consequat.
                  </p>
                  <Link to="#" className="btn orangebg px-3">
                    More Details
                  </Link>
                </div>
              </div>
            </div>
            {/* Repeat similar structure for other blog items */}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Blog
