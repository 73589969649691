import React from 'react'
import NavbarAllFiles from '../NavbarAll/NavbarAllFiles'
import QuranClasses from "../../assets/audio/images/quranclasses.jpg"
const ServicesDetailQuranClasses = () => {
  return (
    <>
    <NavbarAllFiles/>
    <section>
                <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div class="fixed-bg" style={{backgroundColor:""}}></div>
                    <div class="container">
                        <div class="page-title-wrap text-center w-100">
                            <div class="page-title-inner d-inline-block">
                                <h1 class="mb-0">Services Detail</h1>
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><a href="/" title="Home">Maktab - Home</a></li>
                                    <li class="breadcrumb-item"><a href="/services" title="">Services</a></li>
                                    <li class="breadcrumb-item active">Detail</li>
                                    <li class="breadcrumb-item active">Quran Classes</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="w-100 pt-120 pb-250 position-relative">
                   
                    <div class="container">
                        <div class="services-detail-wrap w-100">
                            <div class="services-detail-info-wrap w-100">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-lg-6 order-md-1">
                                        <div class="service-detail-img position-relative w-100">
                                            <img class="img-fluid w-100" src={QuranClasses} alt="Service Detai"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-lg-6">
                                        <div class="services-detail-info w-100">
                                            <i class="rounded-circle d-inline-block thm-bg flaticon-quran-2"></i>
                                            <h2 class="mb-0">Quran Classes</h2>
                                            <span class="d-block"></span>
                                            <ul class="services-info-list mb-0 list-unstyled">
                                                {/* <li>Recognition, drive special offers</li>
                                                <li>Launch a new product or communicate</li>
                                                <li>Message and generate action</li>
                                                <li>Campaign to powerfully convey</li>
                                                <li>Outdoor media, PR and digital marketing</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="services-detail-desc w-100">
                                <p class="mb-0">Learn the essentials of Quran at Jama Masjid Zakaria.</p>
                                <p class="mb-0">The primary aims of Quran classes at our Masjid is:</p>
                                <ul class="services-info-list mb-0 list-unstyled">
                                                <li>Spiritual Development: To deepen the spiritual connection of participants with the Quran, fostering a stronger relationship with Allah.</li>
                                                <li>Knowledge Acquisition: To impart knowledge of the Quranic text, its meanings, and teachings.</li>
                                                <li>Character Building: To instill moral values and principles derived from the Quranic teachings.</li>
                                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
   
    </>
  )
}

export default ServicesDetailQuranClasses
